import { AppManifest, PageSettings } from '@wix/platform-editor-sdk';

type PageSettingsOptions = NonNullable<AppManifest['pages']>['pageSettings'];

const pageInfoSettings: PageSettings = {
  title: 'Page Info',
  type: 'page_info',
  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
};

const permissionsSettings: PageSettings = {
  title: 'Permissions',
  type: 'permissions',
  helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
};

const seoSettings: PageSettings = {
  title: 'SEO Basics',
  type: 'seo',
  helpId: 'a621022f-17fa-433a-b4f5-d43994e8c4f6',
};

const layoutSettings: PageSettings = {
  title: 'Layout',
  type: 'layout',
};

export const getPageSettings = (): PageSettingsOptions => ({
  default: [pageInfoSettings, layoutSettings, permissionsSettings, seoSettings],
});
