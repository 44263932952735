import { instanceValue } from '@wix/wix-instance-parser-js';
import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import {
  menuToggle,
  membersAreaInstallationError,
  addPageButtonClickMembersPages,
  loadMembersCustomPages,
  verticalTriggeredMaInstallInitiated,
  verticalTriggeredMaInstallSuccess,
  maPageInstalled,
  managePagesActionDelete,
  managePagesAddMemberPagesClick,
  managesPagesModalTabNavigation,
  membersProfileSelection,
  memberPagesSettingsOpen,
  memberPagesSettingsAction,
  memberPagesSettingsSaveConfirm,
} from '@wix/bi-logger-members-app-users/v2';
import {
  menuToggleParams,
  addPageButtonClickMembersPagesParams,
  loadMembersCustomPagesParams,
  membersProfileSelectionParams,
  maPageInstalledParams,
  verticalTriggeredMaInstallSuccessParams,
  verticalTriggeredMaInstallInitiatedParams,
  memberPagesSettingsActionParams,
} from '@wix/bi-logger-members-app-users/v2/types';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { SANTA_MEMBERS_APP_ID } from '../editor-app-module/constants';
import { log } from './monitoring';
import { GeneralSettingsAction, Origin, ReferralInfo } from '../types/bi';
import { ProfileType } from '../types/blog-writer';
import { PageToModify } from '../types/general-settings';
import { Page } from '../panels/generalSettings/types';
import { arePagesEqual } from './pages';
import { ConfirmationPanelType } from '../panels/generalSettings/utils';

const APP_DEF_TO_BI_VALUE: Record<string, GeneralSettingsAction> = {
  [MA_APP_IDS.ABOUT.appDefinitionId]: GeneralSettingsAction.Profile,
  [MA_APP_IDS.FOLLOWERS.appDefinitionId]: GeneralSettingsAction.Followers,
  [MA_APP_IDS.ALL_MEMBERS.appDefinitionId]: GeneralSettingsAction.Members,
};

type Modifications = {
  appsToInstall: PageToModify[];
  appsToRemove: PageToModify[];
  profilePageType: ProfileType.BWP_ONLY | ProfileType.BWP_ALL | null;
};

const createBIService = async ({
  editorSDK,
  withClientId,
  options,
}: {
  editorSDK: EditorSDK;
  withClientId?: boolean;
  options?: EditorReadyOptions;
}) => {
  const biService: BIService = new BIService();
  const originInfo = options?.origin.info?.type as string;
  await biService.init(editorSDK, withClientId, originInfo);
  return biService;
};

interface BaseProps {
  app_id?: string;
  instance_id?: string;
  biToken?: string;
  client_id?: string;
  originInfo?: string;
}

class BIService {
  logger: Logger;
  baseProps: BaseProps = {};

  constructor() {
    this.logger = webBiLogger.factory().logger();
  }

  init = async (editorSDK: EditorSDK, withClientId: boolean = true, originInfo: string) => {
    const instance = await editorSDK.info.getAppInstance('');

    if (!instance) {
      log('missing instance in platform however the editor script will proceed');
    }

    const instanceId = instance ? instanceValue.getValueFromInstance('instanceId', instance) : undefined;
    const biToken = instance ? instanceValue.getValueFromInstance('biToken', instance) : undefined;
    this.baseProps = { app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken, originInfo };
    if (withClientId) {
      this.baseProps.client_id = instance ? instanceValue.getValueFromInstance('uid', instance) : undefined;
    }
  };

  logMembersMenuToggle = ({ pageId, pageName, toggle_status, is_social }) => {
    const toggleName = 'Members Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logLoginMenuToggle = ({ pageId, pageName, toggle_status, is_social }: Partial<menuToggleParams>) => {
    const toggleName = 'Login Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logInstallationFailure = (errorMsg: string) => {
    const props = { ...this.baseProps, errorMsg, appInstanceId: this.baseProps.app_id };
    delete props.app_id;
    return this.logger.report(membersAreaInstallationError(props));
  };

  addPageButtonClick = ({ origin, pageName, pageId, appId }: Partial<addPageButtonClickMembersPagesParams>) => {
    return this.logger.report(addPageButtonClickMembersPages({ ...this.baseProps, origin, pageName, pageId, appId }));
  };

  loadMembersCustomPages = ({ pageName, pageId, appId }: Partial<loadMembersCustomPagesParams>) => {
    return this.logger.report(loadMembersCustomPages({ ...this.baseProps, pageName, pageId, appId }));
  };

  verticalTriggeredMaInstallInitiated = ({ originAppId }: Partial<verticalTriggeredMaInstallInitiatedParams>) => {
    return this.logger.report(verticalTriggeredMaInstallInitiated({ ...this.baseProps, originAppId }));
  };

  verticalTriggeredMaInstallSuccess = ({ originAppId }: Partial<verticalTriggeredMaInstallSuccessParams>) => {
    return this.logger.report(verticalTriggeredMaInstallSuccess({ ...this.baseProps, originAppId }));
  };

  maPageInstalled = ({ originAppId, pageName }: Partial<maPageInstalledParams>) => {
    return this.logger.report(maPageInstalled({ ...this.baseProps, originAppId, pageName }));
  };

  managePagesActionDelete = ({ pageId, pageName, value, appId }) => {
    return this.logger.report(managePagesActionDelete({ ...this.baseProps, pageId, pageName, value, appId }));
  };

  managePagesAddMemberPagesClick = ({ origin, referralInfo }: { origin: Origin; referralInfo?: ReferralInfo }) => {
    return this.logger.report(managePagesAddMemberPagesClick({ ...this.baseProps, origin, referralInfo }));
  };

  managesPagesModalTabNavigation = ({ tabName }) => {
    return this.logger.report(managesPagesModalTabNavigation({ ...this.baseProps, tabName }));
  };

  membersProfileSelection = ({ value }: Partial<membersProfileSelectionParams>) => {
    return this.logger.report(membersProfileSelection({ ...this.baseProps, value }));
  };

  memberPagesSettingsOpen = (referralInfo: ReferralInfo) => {
    return this.logger.report(memberPagesSettingsOpen({ ...this.baseProps, referralinfo: referralInfo }));
  };

  togglePublicPageAvailability = (isEnabled: boolean) => {
    const value = isEnabled ? GeneralSettingsAction.ShowPublicOn : GeneralSettingsAction.ShowPublicOff;
    return this.memberPagesSettingsAction({ value });
  };

  onProfilePageTypeChange = (profileType: ProfileType.BWP_ONLY | ProfileType.BWP_ALL) => {
    const value =
      profileType === ProfileType.BWP_ONLY
        ? GeneralSettingsAction.ProfileBlogWriters
        : GeneralSettingsAction.ProfileAll;
    return this.memberPagesSettingsAction({ value });
  };

  onFindMorePagesClick = () => {
    return this.memberPagesSettingsAction({ value: GeneralSettingsAction.FindMorePagesCTA });
  };

  onBackToOverviewClick = () => {
    return this.memberPagesSettingsAction({ value: GeneralSettingsAction.BackToOverviewCTA });
  };

  onPageToggle = (appDefinitionId: string) => {
    return this.memberPagesSettingsAction({ value: APP_DEF_TO_BI_VALUE[appDefinitionId] });
  };

  memberPagesSettingsSaveConfirm = (
    modifications: Modifications,
    pages: Page[],
    showPublicPagesToggleEnabled: boolean,
  ) => {
    if (!showPublicPagesToggleEnabled) {
      return this.pagesSettingsSaveConfirm({ selection: undefined });
    }

    const { appsToRemove, appsToInstall } = modifications;
    const selection = pages
      .filter((page) =>
        page.isInstalled
          ? !appsToRemove.some(({ integrationApplication }) => {
              return arePagesEqual(integrationApplication, page.integrationApplication);
            })
          : appsToInstall.some(({ integrationApplication }) => {
              return arePagesEqual(integrationApplication, page.integrationApplication);
            }),
      )
      .map(({ integrationApplication }) => APP_DEF_TO_BI_VALUE[integrationApplication.appDefinitionId])
      .toString();

    return this.pagesSettingsSaveConfirm({ selection });
  };

  pagesSettingsSaveConfirm = ({ selection, modalName }: { selection?: string; modalName?: ConfirmationPanelType }) => {
    return this.logger.report(
      memberPagesSettingsSaveConfirm({ ...this.baseProps, origin: Origin.Editor, selection, modalName }),
    );
  };

  private readonly memberPagesSettingsAction = ({ value }: memberPagesSettingsActionParams) => {
    return this.logger.report(memberPagesSettingsAction({ ...this.baseProps, value, origin: Origin.Editor }));
  };
}

export { createBIService, BIService };
