import { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { toMonitored } from '../../../utils/monitoring';

import { APP_TOKEN } from '../../constants';
import enforceSequentiality from '../../enforceSequentiality';
import { setIsUninstalling } from '../../services/applicationState';
import { getPublicApplications } from './services/members-area-page';
import { navigateToMembersAreaPage } from './services/navigation';
import { MembersAreaPrivateApi } from '../types';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => ({
  hasSocialPages: async () => {
    const action = async () => {
      await navigateToMembersAreaPage(editorSDK);
      const installedPublicApps = await getPublicApplications(editorSDK);
      return installedPublicApps.length > 0;
    };

    return enforceSequentiality('private-api.hasSocialPages', action);
  },
  // TODO: Implement with custom pages
  removePage: (pageData: { id: string }) => {
    throw new Error('[MAV2] PrivateAPI.removePage is not implemented');
  },
  uninstall: async () => {
    const action = async () => {
      setIsUninstalling(true);
      await editorSDK.application.uninstall('', { openConfirmation: false });
      await editorSDK.history.add(APP_TOKEN, { label: 'Deleting Members Area' });
      await editorSDK.editor.save(APP_TOKEN);
      setIsUninstalling(false);
    };

    return enforceSequentiality('private-api.uninstall', () => toMonitored('uninstall', action));
  },
  /** @deprecated */
  updatePageData: ({ pageRef, pageData }: { pageRef: PageRef; pageData: PageData }) => {
    throw new Error('[MAV2] PrivateAPI.updatePageData is not implemented');
  },
  /** @deprecated */
  getAllRouters: () => {
    throw new Error('[MAV2] PrivateAPI.getAllRouters is not implemented');
  },
  /** @deprecated */
  getRouterDataByPage: (pageRef: PageRef) => {
    throw new Error('[MAV2] PrivateAPI.getRouterDataByPage is not implemented');
  },
  /** @deprecated */
  updatePageDataInRouter: (pageRef: PageRef, dataFieldToUpdate: string, updatedData: string | object) => {
    throw new Error('[MAV2] PrivateAPI.updatePageDataInRouter is not implemented');
  },
  /** @deprecated */
  saveInnerRoute: (newInnerRoute: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
    throw new Error('[MAV2] PrivateAPI.saveInnerRoute is not implemented');
  },
  /** @deprecated */
  savePasswordPermissions: (pageRef: PageRef, privacy: string, plainPassword: string) => {
    throw new Error('[MAV2] PrivateAPI.savePasswordPermissions is not implemented');
  },
  /** @deprecated */
  renamePage: (pageName: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
    throw new Error('[MAV2] PrivateAPI.renamePage is not implemented');
  },
});
