import { EditorSDK } from '@wix/platform-editor-sdk';

import { getControllerConfig } from '../wrappers/controllers';
import { CONTROLLER_COMP_CUSTOM_ID } from '../constants';
import { shouldUseMembersAreaOnBlocksInstallFlow } from '../../utils/experiments';

export const getIsMembersAreaV2Context = async (editorSDK: EditorSDK) => {
  try {
    const controllerConfig = await getControllerConfig(editorSDK, CONTROLLER_COMP_CUSTOM_ID);

    return controllerConfig?.isMembersAreaV2 ?? shouldUseMembersAreaOnBlocksInstallFlow();
  } catch (e) {
    return shouldUseMembersAreaOnBlocksInstallFlow();
  }
};
