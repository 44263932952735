import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { WidgetId } from '@wix/members-area-app-definitions';
import { EditorReadyFn, GetAppManifest } from '@wix/platform-editor-sdk';

import { MembersAreaOnEventFn as _MembersAreaOnEventFn } from './events';
import { MembersAreaPublicApi as _MembersAreaPublicApi } from './public-api';
import { MembersAreaPrivateApi as _MembersAreaPrivateApi } from './private-api';

export { AppManagerAction, MembersAreaEvent } from './events';

export type MembersAreaOnEventFn = _MembersAreaOnEventFn;

export type MembersAreaPublicApi = _MembersAreaPublicApi;

export type MembersAreaPrivateApi = _MembersAreaPrivateApi;

export interface MembersAreaPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifest;
  onEvent: MembersAreaOnEventFn;
}

export interface WidgetPluginPointer {
  appDefinitionId: string;
  widgetId: WidgetId;
}

export interface RouteConfiguration {
  widgetId: WidgetId;
  vfr?: string[];
}

export enum PageTitleType {
  Social = 'social',
  NonSocial = 'non-social',
}

export interface MembersAreaPagePublicApi {
  getInstalledWidgetPlugins: () => Promise<WidgetPluginPointer[]>;
  addWidgetsPlugins: (integrationApplication: IntegrationApplication[]) => Promise<void>;
  removeWidgets: (widgetsIds: string[]) => Promise<void[]>;
  getPublicApplications: () => Promise<WidgetPluginPointer[]>;
  getRoutes: () => Promise<RouteConfiguration[]>;
  updateRoute: (route: RouteConfiguration) => Promise<void>;
  setPageTitle: (pageTitleType: PageTitleType) => Promise<void>;
}
