import { EditorSDK, PageRef, PlatformEvent } from '@wix/platform-editor-sdk';

export enum MembersAreaEvent {
  RenameRouter = 'renameRouter',
  RemovePage = 'removePage',
  Uninstall = 'uninstall',
  ManagePages = 'managePages',
  CreateBlankPage = 'createBlankPage',
  GeneralSettings = 'generalSettings',
  MemberPrivacyDashboard = 'memberPrivacyDashboard',
}

export enum AppManagerAction {
  AddMemberPage = 'addMemberPage',
  OpenMembersAreaPagesPanel = 'openMembersAreaPagesPanel',
  OpenMembersAddPanel = 'openMembersAddPanel',
  OpenMembersAccountBmDashboard = 'openMembersAccountBmDashboard',
  OpenBadgesDashboard = 'openBadgesPage',
  OpenSiteMembersDashboard = 'openSiteMembersDashboard',
}

interface RemoveMembersPageEvent {
  eventType: MembersAreaEvent.RemovePage;
  eventPayload: { pageRef: PageRef };
}

export type MembersAreaOnEventFn = (
  options: PlatformEvent | RemoveMembersPageEvent | { eventType: MembersAreaEvent; eventPayload: any },
  editorSDK: EditorSDK,
) => Promise<void>;
